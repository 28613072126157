html {
  background-color: #42009d;
  height: 100%;
  overflow: hidden;
}

body {
  color: #fafafa;
  width: 100%;
  height: 100%;
  min-height: 100%;
  margin: 0;
  font-family: Red Hat Display, sans-serif;
  font-size: 12pt;
  font-weight: 200;
  position: absolute;
  overflow: auto;
}

main#scene {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
}

main#scene .container {
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

footer {
  bottom: max(15px, env(safe-area-inset-bottom));
  text-align: center;
  margin: 0 auto;
  font-size: 0;
  display: inline-block;
  position: absolute;
  left: 0;
  right: 0;
}

footer a + a {
  margin-left: 15px;
}

footer img {
  opacity: .6;
  width: 45px;
}

footer img:hover {
  opacity: 1;
}

.cover .background {
  filter: blur(8px);
  height: 110%;
  position: absolute;
  top: -5%;
  left: -5%;
}

.crowd img {
  width: 120%;
  max-height: 50vh;
  animation: 6s cubic-bezier(.5, .19, .51, .81) infinite alternate crowd;
  position: absolute;
  bottom: -8%;
  left: -10%;
}

.me img {
  height: 50vw;
  max-height: 90vh;
  animation: 6s cubic-bezier(.5, .19, .51, .81) infinite alternate me;
  position: absolute;
  bottom: 0;
  right: 5vw;
}

.name-job-wrap {
  width: 90vw;
  margin: 10px auto 0;
  font-size: min(12.5vw, 33.3333vh);
  font-weight: 500;
  line-height: min(1em, 25vh);
}

.name-job-wrap .name, .name-job-wrap .job {
  font-size: inherit;
  font-weight: inherit;
  margin: 0;
}

.name-job-wrap .job {
  margin-top: min(.5em, 5vh);
}

.name-job-wrap .job span {
  letter-spacing: -.15em;
  text-transform: uppercase;
}

.name-job-wrap .name span {
  letter-spacing: -.05em;
}

@media (orientation: portrait) {
  .me img {
    height: 100%;
    min-height: 70vh;
    max-height: min(200vw, 80vh);
    right: min(-25vw, -20vh);
  }

  .crowd img {
    width: 200vh;
    max-height: 60vh;
  }

  .name-job-wrap {
    font-size: 16.6667vw;
  }

  .name-job-wrap p.job {
    margin-top: .5em;
  }
}

@keyframes crowd {
  0% {
    transform: translate3d(0, 0, 0);
  }

  50% {
    transform: translate3d(0, 2.5%, 0);
  }

  100% {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes me {
  0% {
    transform: rotate3d(0, 0, 1, 0);
  }

  50% {
    transform: rotate3d(0, 0, 1, 1deg);
  }

  100% {
    transform: rotate3d(0, 0, 1, -1deg);
  }
}

* {
  box-sizing: border-box;
}

/*# sourceMappingURL=index.d4125ee9.css.map */
