@mixin bg-image-set($fallback, $imageSet...) {
    background-image: url($fallback);
    @if $imageSet {
        background-image: -webkit-image-set(#{$imageSet});
        background-image: image-set(#{$imageSet});
    }
}

html{
    height: 100%;
    overflow: hidden;
    background-color: #42009d;
}
body {
    min-height: 100%;
    margin: 0;
    font-family: 'Red Hat Display', sans-serif;
    font-weight: 200;
    font-size: 12pt;
    color:#fafafa;
    overflow: hidden;

	overflow: auto;
    position: absolute;
    height: 100%;
    width: 100%;
}
main#scene{
    position: relative;
    overflow: hidden;
    height: 100%;
    width: 100%;

    .container{
        position: absolute;
        display: block;
        left: 0px;
        top: 0px;
        height: 100%;
        width: 100%;
    }
}
footer{
    position: absolute;
    display: inline-block;
    font-size: 0;

    bottom: max(15px, env(safe-area-inset-bottom));
    right: 0;
    left: 0;

    margin: 0 auto;

    text-align: center;
    a + a {
        margin-left: 15px;
    }

    img{
        width: 45px;
        opacity: 0.6;
        &:hover{
            opacity: 1;
        }
    }
}
.cover .background{
    left: -5%;
    top: -5%;
    // width: 110%;
    height: 110%;
    position: absolute;

    // @include bg-image-set(
    //     './images/wall-min.jpg',
    //     url("./images/wall-min.jpg?as=avif") 1x,
    //     url("./images/wall-min.jpg?as=webp") 1x,
    //     url("./images/wall-min.jpg") 1x
    // );

    // background-size: cover;
    // background-repeat: no-repeat;
    // background-position: center center;

    filter: blur(8px);
}
.crowd img{
    position: absolute;
    width: 120%;
    bottom: -8%;
    left: -10%;

    max-height: 50vh;

    animation: 6s cubic-bezier(0.5, 0.19, 0.51, 0.81) 0s infinite alternate crowd ;
}
.me img{
    position: absolute;
    bottom: 0;
    right: 5vw;

    max-height: 90vh;
    height: 50vw;

    animation: 6s cubic-bezier(0.5, 0.19, 0.51, 0.81) 0s infinite alternate me ;
}


.name-job-wrap{
    width: 90vw;
    margin: 0 auto;
    margin-top: 10px;

    font-size: min(12.5vw, 100vh/3);
    line-height: min(1em, 100vh/4);
    font-weight: 500;

    .name, .job{
        font-size: inherit;
        font-weight: inherit;

        margin: 0;
    }

    .job{
        // margin-top: 0.5em;
        margin-top: min(0.5em, calc(100vh/20));
        span{
            letter-spacing: -0.15em;
            text-transform: uppercase;
        }
    } 
    .name span{
        letter-spacing: -0.05em;
    }
}

// @media (orientation: landscape) {
//     body {
//         flex-direction: row;
//     }
// }

@media (orientation: portrait) {
    .me img{
        right: min(-25vw, -80vh/4);
        max-height: min(200vw, 80vh);
        min-height: 70vh;
        height: 100%;
    }
    .crowd img{
        max-height: 60vh;
        width: 200vh;
    }
    .name-job-wrap{
        font-size: calc(100vw/6);
        // line-height: 1em;

        p.job{
            margin-top: 0.5em;
        }
    }
}


@keyframes crowd {
    0% {
      transform:  translate3d(0, 0, 0)  }
    50% {
       transform:  translate3d(0, 2.5%, 0)  } 
    100% {
      transform:  translate3d(0, 0, 0)  } 
    }

@keyframes me {
    0% {
        transform:  rotate3d(0, 0, 1, 0);  }
    50% {
        transform:  rotate3d(0, 0, 1, 1deg);  }
    100% {
        transform:  rotate3d(0, 0, 1, -1deg);  } 
}

*{
    box-sizing: border-box;
}